import React from "react";

import { NavLink } from "react-router-dom";
import { Horizontal } from "gls/lib";
import { stylesheet } from "typestyle";

import { Path } from "../../types/pathType";
import { useUser } from "shared/hooks/useUser";

import { SIZES } from "shared/variables/sizes";
import { COLORS } from "shared/variables/colors";

export const HeaderNavBar = () => {
  const { user, isLoading } = useUser();

  return (
    <Horizontal>
      <nav role="navigation" className={classNames.navBar}>
        <ul>
          <li>
            <NavLink exact to={Path.REAL_TIME_API}>
              Real Time API
            </NavLink>
          </li>
          <li>
            Third-Party API
            <ul className={classNames.dropdown}>
              <li>
                <NavLink exact to={Path.THIRD_PARTY_API_DOCUMENTATION}>
                  Documentation
                </NavLink>
              </li>
              {!isLoading && !user?.isManager && (
                <li>
                  <NavLink exact to={Path.THIRD_PARTY_API_PLAYGROUND}>
                    Playground
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </nav>
    </Horizontal>
  );
};

const classNames = stylesheet({
  navBar: {
    $nest: {
      a: {
        color: COLORS.light2,
        textDecoration: "none",
        $nest: {
          "&:hover": {
            color: COLORS.light4,
          },
        },
      },
      ".active": {
        color: COLORS.light4,
      },
      ul: {
        margin: 0,
        marginLeft: 20,
        paddingLeft: 0,
        $nest: {
          li: {
            $nest: {
              ul: {
                visibility: "hidden",
                opacity: 0,
                position: "absolute",
                marginTop: 30,
                left: 0,
                display: "none",
                $nest: {
                  li: {
                    clear: "both",
                    width: "100%",
                  },
                  "&:hover": {
                    visibility: "visible",
                    opacity: 1,
                    display: "block",
                  },
                },
              },
              "&:hover > ul": {
                visibility: "visible",
                opacity: 1,
                display: "block",
              },
            },
          },
          "&:first-child:after": {
            content: '""',
            border: "0.35rem solid transparent",
            borderBottomColor: "#313648",
            left: 20,
            position: "absolute",
            transform: "translateY(-10px)",
          },
        },
      },
      "& > ul > li": {
        padding: "13px 20px 30px 20px",
      },
      li: {
        display: "block",
        float: "left",
        padding: "13px 15px 15px 15px",
        position: "relative",
        textDecoration: "none",
      },
    },
  },
  dropdown: {
    background: COLORS.dark3,
    borderRadius: SIZES.borderRadius,
    minWidth: 200,
    margin: 0,
    $nest: {
      li: {
        borderBottom: `1px solid ${COLORS.dark2}`,
      },
      "& li:last-child": {
        borderBottom: "none",
      },
    },
  },
});
