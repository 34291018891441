import React, { FC, useState } from "react";

import { style } from "typestyle";
import { Horizontal, Stretch } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { Button } from "shared/components/button/Button";

import { FeedingDataApiDayInput } from "./FeedingDataApiDayInput";
import { FeedingDataApiSiteInput } from "./FeedingDataApiSiteInput";
import { FeedingDataApiCageInput } from "./FeedingDataApiCageInput";

import { FormValues } from "shared/hooks/useFeedingDataApi";

interface Props {
  onFormValuesChange: (values: FormValues) => void;
}

export const FeedingDataApiForm: FC<Props> = ({ onFormValuesChange }) => {
  const [formValues, setFormValues] = useState<FormValues>();
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [selectedSiteId, setSelectedSiteId] = useState<number>();

  const onChangeHandler = (formState: any) => {
    setSelectedSiteId(formState.siteId.value?.value);

    const allInputsHaveValues = Object.keys(formState).every(
      (key) => !!formState[key].value,
    );

    setDisableButton(!allInputsHaveValues);

    if (allInputsHaveValues) {
      setFormValues({
        day: formState.day.value,
        cageId: formState.cageId.value.value,
        siteId: formState.siteId.value.value,
      });
    }
  };

  const onClickHandler = () => {
    onFormValuesChange({ ...(formValues as FormValues) });
  };

  return (
    <Form onChange={onChangeHandler}>
      <Horizontal>
        <Horizontal sizing="stretch">
          <Stretch>
            <FeedingDataApiSiteInput />
          </Stretch>
          <Stretch>
            <FeedingDataApiCageInput siteId={selectedSiteId} />
          </Stretch>
          <Stretch>
            <FeedingDataApiDayInput />
          </Stretch>
          <Stretch verticalAlign="bottom">
            <Button
              onClick={onClickHandler}
              className={button}
              isDisabled={disableButton}
            >
              Get data
            </Button>
          </Stretch>
        </Horizontal>
      </Horizontal>
    </Form>
  );
};

const button = style({
  width: "100%",
  paddingTop: 8,
  marginBottom: 4,
  paddingBottom: 8,
});
