import React, { FC, useMemo } from "react";

import {
  FormSelectInput,
  FormSelectOption,
} from "shared/components/form/FormSelectInput";

export const AggregatedDataApiHourInput: FC = () => {
  const options: FormSelectOption[] = useMemo(() => {
    return [...new Array(AMOUNT_OF_HOURS)].map((_: any, index: number) => {
      return {
        value: index + STARTING_HOUR,
        label: `${index + STARTING_HOUR}:00h`,
      };
    });
  }, []);

  return (
    <FormSelectInput
      name="hour"
      label="Hour of the day"
      options={options}
      isMultiple={false}
    />
  );
};

const STARTING_HOUR = 7;
const AMOUNT_OF_HOURS = 13;
