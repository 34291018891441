import React, { useContext, useEffect, useState } from "react";

import { Content } from "gls/lib";
import { useHistory } from "react-router-dom";

import { Path } from "shared/types/pathType";
import { useLogin } from "shared/hooks/useLogin";
import { useFeedback } from "shared/hooks/useFeedback";
import { Challenge, ChallengeName } from "shared/types/cognitoTypes";

import { DocsContext } from "contexts/DocsContext";
import { LoginOTPForm } from "./LoginOTPForm";
import { OTPSetUpForm } from "./OTPSetUpForm";
import { NewPasswordForm } from "./NewPasswordForm";
import { LoginPasswordForm } from "./LoginPasswordForm";

export const LoginPage = () => {
  const { resetDocs } = useContext(DocsContext);

  const login = useLogin();
  const history = useHistory();
  const feedback = useFeedback();

  useEffect(() => {
    resetDocs();
  }, []);

  const [challenge, setChallenge] = useState<Challenge>({
    name: ChallengeName.PASSWORD_LOGIN,
  });

  const onSuccessfulLogin = (username: string) => {
    history.push(Path.REAL_TIME_API);
    feedback.showPositiveFeedback(`Welcome, ${username}!`);
  };

  const onLoginPasswordSubmitHandler = (username: string, password: string) => {
    login
      .loginWithPassword(username, password)
      .then((response: Challenge | string) => {
        if (typeof response === "string") {
          onSuccessfulLogin(response);
        } else {
          setChallenge(response);
        }
      })
      .catch(() => {
        feedback.showNegativeFeedback("Invalid username or password");
      });
  };

  const onLoginOTPSubmitHandler = (otp: string) => {
    login
      .loginWithOTP(otp)
      .then((username: string) => {
        onSuccessfulLogin(username);
      })
      .catch(() => {
        feedback.showNegativeFeedback("Invalid code or session");
      });
  };

  const onNewPasswordSubmitHandler = (newPassword: string) => {
    login
      .setNewPassword(newPassword)
      .then((challenge: Challenge) => {
        setChallenge(challenge);
        feedback.showPositiveFeedback("Password susccessfully changed");
      })
      .catch(() => {
        feedback.showNegativeFeedback("Invalid new password");
      });
  };

  const onOTPSetUpSubmitHandler = (otp: string) => {
    login
      .setUpOTPClient(otp)
      .then((challenge: Challenge) => {
        setChallenge(challenge);
        feedback.showPositiveFeedback(
          "OTP client successfully set up. Now you can log in",
        );
      })
      .catch(() => {
        feedback.showNegativeFeedback("Could not set up the OTP client");
      });
  };

  return (
    <Content height="75vh" verticalAlign="center" horizontalAlign="center">
      {challenge.name === ChallengeName.PASSWORD_LOGIN && (
        <LoginPasswordForm onSubmit={onLoginPasswordSubmitHandler} />
      )}

      {challenge.name === ChallengeName.OTP_LOGIN && (
        <LoginOTPForm onSubmit={onLoginOTPSubmitHandler} />
      )}

      {challenge.name === ChallengeName.NEW_PASSWORD_REQUIRED && (
        <NewPasswordForm onSubmit={onNewPasswordSubmitHandler} />
      )}

      {challenge.name === ChallengeName.OTP_SETUP && (
        <OTPSetUpForm
          secret={challenge.secret}
          onSubmit={onOTPSetUpSubmitHandler}
        />
      )}
    </Content>
  );
};
