import { FormValidator } from "../types/formTypes";

const emailRegExp = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
);

export const isEmail: FormValidator = {
  error: "It has to be a valid email.",
  isValid: (value: any) => {
    return emailRegExp.test(value);
  },
};
