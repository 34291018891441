import { Content } from "gls/lib";
import React, { FC } from "react";
import { stylesheet } from "typestyle";

export const TableBodyWithoutData: FC = () => {
  return (
    <tbody>
      <tr>
        <td colSpan={100} height={400}>
          <Content className={classNames.noData}>No data</Content>
        </td>
      </tr>
    </tbody>
  );
};

const classNames = stylesheet({
  noData: {
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
  },
});
