import { DocsContext } from "contexts/DocsContext";
import { useContext, useEffect } from "react";

export const useDocs = () => {
  const { docs, fetchDocs } = useContext(DocsContext);

  useEffect(() => {
    fetchDocs();
  }, []);

  return docs;
};
