import { useState, useEffect, useContext, useMemo } from "react";

import { FormContext } from "shared/components/form/Form";
import { FormController, FormValidator } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";
import { getValidationErrors } from "shared/utils/formUtils";

export type InputProps<T> = UseInputParams<T>;

export interface UseInputParams<T> {
  name: string;
  isRequired?: boolean;
  validators?: FormValidator[];
  initialValue?: T | null;
}

export const useInput = <T>({
  name,
  validators,
  isRequired,
  initialValue,
}: UseInputParams<T>): UseInputResponse<T> => {
  const [value, setValue] = useState<T | null>(initialValue ?? null);
  const [formState, setFormState] = useContext<FormController>(FormContext);

  const setIsDirtyToTrueIfItHasChanged = () => {
    if (!formState[name]?.isDirty) {
      setFormState(name, { isDirty: true });
    }
  };

  const isDirty: boolean = useMemo(() => {
    return formState[name]?.isDirty;
  }, [formState]);

  const errors: string[] = useMemo(() => {
    if (isRequired) {
      validators = [...(validators || []), isMandatory];
    }

    return getValidationErrors(validators, value, formState);
  }, [value, formState, isRequired, validators]);

  const isValid: boolean = useMemo(() => {
    return errors.length === 0;
  }, [errors]);

  const onBlurHandler = () => {
    setIsDirtyToTrueIfItHasChanged();
  };

  const onChangeHandler = (value: T | null) => {
    setValue(value);
    setIsDirtyToTrueIfItHasChanged();
  };

  useEffect(() => {
    setFormState(name, {
      value,
      isValid,
    });
  }, [value, isValid]);

  return {
    value,
    errors,
    isDirty,
    onBlurHandler,
    onChangeHandler,
  };
};

interface UseInputResponse<T> {
  value: T | null;
  errors: string[];
  isDirty: boolean;
  onBlurHandler: () => void;
  onChangeHandler: (value: T | null) => void;
}
