import { Env } from "shared/types/envType";

export const env: Env = {
  managers: {
    documentationApi:
      `${process.env.REACT_APP_DOCUMENTATION_API_URL_MANAGERS}?getAll=1` || "",
    cognito: {
      region: process.env.REACT_APP_AWS_REGION || "",
      clientId: process.env.REACT_APP_MANAGER_APPLICATION_ID || "",
      userPoolId: process.env.REACT_APP_MANAGEMENT_USER_POOL_ID || "",
    },
  },
  thirdPartyUsers: {
    documentationApi:
      process.env.REACT_APP_DOCUMENTATION_API_URL_THIRDPARTY || "",
    cognito: {
      region: process.env.REACT_APP_AWS_REGION || "",
      clientId: process.env.REACT_APP_THIRD_PARTY_APP_CLIENT_ID || "",
      userPoolId: process.env.REACT_APP_THIRD_PARTY_USER_POOL_ID || "",
    },
  },
  common: {
    thirdPartyApi: process.env.REACT_APP_NEMO_THIRD_PARTY_URL || "",
  },
};
console.log("ENV", env);
