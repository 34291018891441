import { FC, useState } from "react";

import { style } from "typestyle";
import { Vertical, Content, Stretch } from "gls/lib";

import { Tabs } from "shared/components/tabs/Tabs";
import { TabContent } from "shared/components/tabs/TabContent";
import { PageContainer } from "shared/components/page-container/PageContainer";

import { FeedingDataApi } from "./apis/feeding-data/FeedingDataApi";
import { AggregatedDataApi } from "./apis/aggregated-data/AggregatedDataApi";

import { SIZES } from "shared/variables/sizes";

export const ThirdPartyApiPlaygroundPage: FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.aggregatedData);

  return (
    <PageContainer>
      <Stretch width="100%" horizontalAlign="center" scroll="vertical">
        <Vertical
          spacing={5}
          width="100%"
          maxWidth={SIZES.container}
          padding={[0, 20]}
        >
          <Content className={title}>Third-Party API Playground</Content>

          <Tabs
            tabs={[TABS.aggregatedData, TABS.feedingData]}
            activeTab={activeTab}
            onChange={setActiveTab}
          >
            <TabContent name={TABS.aggregatedData}>
              <AggregatedDataApi />
            </TabContent>

            <TabContent name={TABS.feedingData}>
              <FeedingDataApi />
            </TabContent>
          </Tabs>
        </Vertical>
      </Stretch>
    </PageContainer>
  );
};

const title = style({
  fontSize: 36,
  fontWeight: "bold",
  paddingTop: 50,
  paddingBottom: 25,
});

const TABS = {
  feedingData: "Feeding data",
  aggregatedData: "Aggregated data",
};
