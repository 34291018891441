import React from "react";

import { stylesheet } from "typestyle";
import { Horizontal } from "gls/lib";

import { HeaderLogo } from "./HeaderLogo";
import { HeaderLogout } from "./Logout";
import { HeaderNavBar } from "./HeaderNavBar";

import { SIZES } from "shared/variables/sizes";
import { COLORS } from "../../variables/colors";

export const Header = () => {
  return (
    <Horizontal className={classNames.header}>
      <HeaderLogo />
      <HeaderNavBar />
      <HeaderLogout />
    </Horizontal>
  );
};

const classNames = stylesheet({
  header: {
    height: SIZES.headerHeight,
    paddingLeft: 25,
    paddingRight: 25,
    background: COLORS.dark3,
  },
});
