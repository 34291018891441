import React from "react";

import { Content } from "gls/lib";
import { stylesheet } from "typestyle";
import { useTable, Column, useFilters } from "react-table";

import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

import { COLORS } from "../../variables/colors";
import { percent } from "csx";

export type TableColumn<T extends object = {}> = Column<T> & {
  widget?: (props: any) => JSX.Element | string;
  align?: "left" | "center" | "right";
};

interface Props<T extends object> {
  data: T[];
  columns: TableColumn<T>[];
  isLoading?: boolean;
  hideHeaders?: boolean;
  onRowClick?: (row: T) => void;
}

export const Table = <T extends {}>({
  data,
  columns,
  isLoading,
  hideHeaders,
  onRowClick,
}: Props<T>) => {
  const table = useTable<T>({ columns, data }, useFilters);

  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } =
    table;

  return (
    <Content
      maxHeight={percent(100)}
      scroll="vertical"
      className={classNames.tableWrapper}
    >
      <table {...getTableProps()} className={classNames.table}>
        {!hideHeaders && <TableHeader headerGroups={headerGroups} />}

        <TableBody
          rows={rows}
          columns={columns}
          isLoading={!!isLoading}
          onRowClick={onRowClick}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
        />
      </table>
    </Content>
  );
};

const classNames = stylesheet({
  tableWrapper: {
    width: "100%",
    background: COLORS.dark1,
    $nest: {
      "&::-webkit-scrollbar": {
        background: COLORS.dark3,
        height: 13,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
        backgroundColor: COLORS.light2,
      },
    },
  },
  table: {
    width: "100%",
    textAlign: "center",
    borderCollapse: "collapse",
    position: "relative",
  },
});
