import React from "react";

import { Vertical } from "gls/lib";
import { keyframes, stylesheet } from "typestyle";

export const Loader: React.FC = ({ children }) => {
  return (
    <Vertical className={classNames.container} spacing={0}>
      <div className={classNames.loader} />

      {children && <label className={classNames.text}>{children}</label>}
    </Vertical>
  );
};

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const classNames = stylesheet({
  container: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    marginTop: 10,
  },
  loader: {
    border: "3px solid #f3f3f3",
    borderTop: "3px solid #3498db",
    borderRadius: "50%",
    width: 20,
    height: 20,
    animation: `${spin} 2s linear infinite`,
  },
});
