import React from "react";

import { Vertical } from "gls";
import { ToastProvider } from "react-toast-notifications";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";

import { Path } from "shared/types/pathType";
import { LoginPage } from "pages/login/LoginPage";
import { PublicRoute } from "shared/components/routes/PublicRoute";
import { PrivateRoute } from "shared/components/routes/PrivateRoute";
import { ForgotPasswordPage } from "pages/forgot-password/ForgotPasswordPage";
import { DocsContextProvider } from "contexts/DocsContext";
import { ApolloClientProvider } from "./graphql/ApolloClient";
import { RealTimeApiDocumentationPage } from "pages/real-time-api/RealTimeApiPage";
import { UnAuthorizedPage } from "pages/unauthorized/UnAuthorizedPage";
import { ThirdPartyApiPlaygroundPage } from "pages/third-party-api-playground/ThirdPartyApiPlaygroundPage";

import { ThirdPartyApiDocumentationPage } from "pages/third-party-api-documentation/ThirdPartyApiDocumentationPage";

function App() {
  return (
    <Vertical height="100vh" spacing={0}>
      <ApolloClientProvider>
        <ToastProvider
          placement="bottom-left"
          autoDismiss
          transitionDuration={250}
        >
          <DocsContextProvider>
            <BrowserRouter>
              <Switch>
                <PublicRoute
                  isRestricted
                  path={Path.LOGIN}
                  component={LoginPage}
                />

                <PublicRoute
                  isRestricted
                  path={Path.FORGOT_PASSWORD}
                  component={ForgotPasswordPage}
                />

                <PrivateRoute
                  exact
                  path={Path.UNAUTHORIZED}
                  component={UnAuthorizedPage}
                />

                <PrivateRoute
                  exact
                  path={Path.THIRD_PARTY_API_DOCUMENTATION}
                  requiredPermission={"hasCloudAccess"}
                  component={ThirdPartyApiDocumentationPage}
                />

                <PrivateRoute
                  exact
                  path={Path.THIRD_PARTY_API_PLAYGROUND}
                  requiredPermission={"hasCloudAccess"}
                  component={ThirdPartyApiPlaygroundPage}
                />

                <PrivateRoute
                  exact
                  path={Path.REAL_TIME_API}
                  requiredPermission={"hasRealtimeAccess"}
                  component={RealTimeApiDocumentationPage}
                />

                <Redirect to={Path.UNAUTHORIZED} />
              </Switch>
            </BrowserRouter>
          </DocsContextProvider>
        </ToastProvider>
      </ApolloClientProvider>
    </Vertical>
  );
}

export default App;
