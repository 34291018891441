import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { Moment } from "moment";

export interface UseAggregatedDataApiResponse {
  data: any[] | undefined;
  getData: (formValues: FormValues) => void;
  errorMessage: string | undefined;
}

export const useAggregatedDataApi = (): UseAggregatedDataApiResponse => {
  const [batches, setBatches] = useState<any[]>([]);
  const [batchCount, setBatchCount] = useState<number>(0);
  const [formValues, setFormValues] = useState<FormValues>();
  const [mergedBatches, setMergedBatches] = useState<any[]>();
  const [requestsCount, setRequestsCount] = useState<number>(0);

  const [getAggregatedData, { data, error }] = useLazyQuery(AGGREGATED_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (error) {
      setBatchCount(0);
    }
  }, [error]);

  useEffect(() => {
    if (requestsCount > 0) {
      setBatches([]);
      setBatchCount(1);
    }
  }, [requestsCount]);

  useEffect(() => {
    if (batchCount > 0) {
      if (batchCount <= NUMBER_OF_TIME_GROUPS_IN_HOUR) {
        const hours = ((formValues?.hour as number) % 12)
          .toString()
          .padStart(2, "0");

        const minutes = (15 * (batchCount - 1)).toString().padStart(2, "0");

        const meridiam = (formValues?.hour as number) < 12 ? "AM" : "PM";

        getAggregatedData({
          variables: {
            day: (formValues?.day as Moment).format("YYYY-MM-DD"),
            siteId: formValues?.siteId,
            cageId: formValues?.cageId,
            timeGroup: `${hours}:${minutes}${meridiam}`,
          },
        });
      } else {
        setMergedBatches(batches);
      }
    }
  }, [batchCount]);

  useEffect(() => {
    if (data) {
      setBatchCount(batchCount + 1);
      setBatches([...batches, ...data.aggregatedData]);
    }
  }, [data]);

  return {
    data: mergedBatches,
    errorMessage: error?.message,
    getData: (formValues: FormValues) => {
      setFormValues(formValues);
      setRequestsCount(requestsCount + 1);
    },
  };
};

export interface FormValues {
  day: Moment;
  hour: number;
  siteId: number;
  cageId: number;
}

const NUMBER_OF_TIME_GROUPS_IN_HOUR = 4;

const AGGREGATED_DATA = gql`
  query AggregatedData(
    $day: String!
    $siteId: Int!
    $cageId: Int!
    $timeGroup: String!
  ) {
    aggregatedData(
      day: $day
      siteId: $siteId
      cageId: $cageId
      timeGroup: $timeGroup
    ) {
      timeStamp
      activity
      numOfPellets
      isAlert
      rateGramPerSec
      feedScore
      closeCamera
      schooling
      severity
      density
      speed
      balling
      visibility
      depth
      currentVisitnumber
      feedTimeS
      feedGrams
      cumulativeFeedTimeS
      cumulativeFeedGrams
      OXYGEN_0_6m
      OXYGEN_6_12m
      OXYGEN_12_20m
      SALINITY_0_6m
      SALINITY_6_12m
      SALINITY_12_20m
      SATURATION_0_6m
      SATURATION_6_12m
      SATURATION_12_20m
      TEMPERATURE_0_6m
      TEMPERATURE_6_12m
      TEMPERATURE_12_20m
    }
  }
`;
