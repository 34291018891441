import React from "react";
import SwaggerUI from "swagger-ui-react";

import { Horizontal } from "gls/lib";
import { FC, useMemo } from "react";

import { Loader } from "shared/components/loader/Loader";
import { useDocs } from "shared/hooks/useDocs";
import { PageContainer } from "shared/components/page-container/PageContainer";

import "./styles.css";
import "swagger-ui-react/swagger-ui.css";

export const RealTimeApiDocumentationPage: FC = () => {
  const docs = useDocs();

  const realTimeDocs = useMemo(() => {
    return docs?.marlin ? JSON.parse(docs.marlin) : null;
  }, [docs]);

  return (
    <PageContainer>
      <Horizontal
        width="100%"
        height="100%"
        scroll="vertical"
        horizontalAlign="center"
      >
        {realTimeDocs ? (
          <SwaggerUI spec={realTimeDocs} supportedSubmitMethods={[]} />
        ) : (
          <Loader />
        )}
      </Horizontal>
    </PageContainer>
  );
};
