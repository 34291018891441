import React from "react";

import { Vertical } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { isEmail } from "shared/validators/isEmailValidator";
import { FormState } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";
import { FormTextInput } from "shared/components/form/FormTextInput";

interface Props {
  onSubmit: (username: string) => void;
}

export const ForgotPasswordUsernameForm: React.FC<Props> = ({ onSubmit }) => {
  const onSubmitHandler = ({ username }: FormState) => {
    onSubmit(username.value);
  };

  return (
    <Form
      title="Forgot your password?"
      onSubmit={onSubmitHandler}
      submitButtonText="Next"
    >
      <Vertical width={300} spacing={15}>
        <FormTextInput
          label="Username"
          name="username"
          validators={[isMandatory, isEmail]}
        />
      </Vertical>
    </Form>
  );
};
