import React from "react";

import { stylesheet } from "typestyle";
import { useHistory } from "react-router-dom";
import { Content, Horizontal, Stretch } from "gls/lib";

import { Path } from "../../types/pathType";
import { useUser } from "../../hooks/useUser";
import { useFeedback } from "../../hooks/useFeedback";
import { removeTokens } from "../../utils/jwtUtils";

import { COLORS } from "../../variables/colors";

export const HeaderLogout = () => {
  const history = useHistory();

  const { user, isLoading } = useUser();
  const { showPositiveFeedback } = useFeedback();

  const onClickHandler = () => {
    removeTokens();
    showPositiveFeedback("Successfully logged out");

    history.push(Path.LOGIN);
  };

  return (
    <Stretch verticalAlign="center" horizontalAlign="right">
      {!isLoading && user && (
        <Horizontal spacing={0}>
          <Content className={classNames.username}>{user.username}</Content>

          <Content onClick={onClickHandler} className={classNames.logout}>
            Logout
          </Content>
        </Horizontal>
      )}
    </Stretch>
  );
};

const classNames = stylesheet({
  logout: {
    cursor: "pointer",
  },
  username: {
    color: COLORS.light2,
    marginRight: 10,
  },
});
