export const SIZES = {
  container: 1460,
  fontSize1: 12.5,
  fontSize2: 15,
  fontSize3: 20,
  fontSize4: 25,
  lineHeight: 1.5,
  input: 375,
  borderRadius: 5,
  headerHeight: 50,
};
