import React, { FC } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { style } from "typestyle";

library.add(faFilter);
library.add(faDownload);

export const Icon: FC<{
  icon: FontAwesomeIconProps["icon"];
  color?: string;
}> = ({ icon, color }) => (
  <FontAwesomeIcon
    icon={icon}
    color={color}
    className={style({ height: "17px" })}
  />
);
