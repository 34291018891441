import React from "react";

import { stylesheet } from "typestyle";
import { HeaderGroup } from "react-table";

import { COLORS } from "../../variables/colors";
import { Stretch } from "gls/lib";
import { TableColumn } from "./Table";

interface TableHeaderProps<T extends object> {
  headerGroups: HeaderGroup<T>[];
}

export const TableHeader = <T extends {}>({
  headerGroups,
}: TableHeaderProps<T>) => {
  return (
    <thead>
      {headerGroups.map((headerGroup: HeaderGroup<T>) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            return (
              <th
                {...column.getHeaderProps()}
                className={classNames.tableHeaderCell}
              >
                <Stretch
                  horizontalAlign={
                    (column as HeaderGroup<T> & TableColumn).align || "center"
                  }
                >
                  {column.Header}
                </Stretch>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

const classNames = stylesheet({
  tableHeaderCell: {
    background: COLORS.dark3,
    padding: "0.75rem",
    position: "sticky",
    top: 0,
  },
});
