import axios from "axios";
import React, { FC, useEffect, useState } from "react";

import { env } from "env/env";
import { getIdToken } from "shared/utils/jwtUtils";
import { useFeedback } from "shared/hooks/useFeedback";

import {
  FormSelectInput,
  FormSelectOption,
} from "shared/components/form/FormSelectInput";

interface Props {
  siteId: number | undefined;
}

export const AggregatedDataApiCageInput: FC<Props> = ({ siteId }) => {
  const { showNegativeFeedback } = useFeedback();
  const [cagesOptions, setCagesOptions] = useState<FormSelectOption[]>([]);

  useEffect(() => {
    setCagesOptions([]);

    if (siteId) {
      axios
        .get(`${env.common.thirdPartyApi}/cages?siteId=${siteId}`, {
          headers: {
            Authorization: getIdToken(),
          },
        })
        .then((response) => response.data)
        .then((cages) =>
          cages.map((cage) => ({
            value: cage,
            label: `Cage ${cage}`,
          })),
        )
        .then(setCagesOptions)
        .catch(() => showNegativeFeedback("Couldn't load cages."));
    }
  }, [siteId]);

  return (
    <FormSelectInput
      key={siteId}
      name="cageId"
      label="Cage"
      options={cagesOptions}
      isMultiple={false}
    />
  );
};
