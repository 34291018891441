import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { Moment } from "moment";

export interface UseFeedingDataApiResponse {
  data: any[] | undefined;
  getData: (formValues: FormValues) => void;
  errorMessage: string | undefined;
}

export const useFeedingDataApi = (): UseFeedingDataApiResponse => {
  const [formValues, setFormValues] = useState<FormValues>();
  const [requestsCount, setRequestsCount] = useState<number>(0);

  const [getFeedingData, { data, error }] = useLazyQuery(FEEDING_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (requestsCount > 0) {
      getFeedingData({
        variables: {
          day: (formValues?.day as Moment).format("YYYY-MM-DD"),
          siteId: formValues?.siteId,
          cageId: formValues?.cageId,
        },
      });
    }
  }, [requestsCount]);

  return {
    data: data?.feedingData,
    errorMessage: error?.message,
    getData: (formValues: FormValues) => {
      setFormValues(formValues);
      setRequestsCount(requestsCount + 1);
    },
  };
};

export interface FormValues {
  day: Moment;
  siteId: number;
  cageId: number;
}

const FEEDING_DATA = gql`
  query FeedingData($day: String!, $siteId: Int!, $cageId: Int!) {
    feedingData(day: $day, siteId: $siteId, cageId: $cageId) {
      startTime
      endTime
      currentVisitNumber
      remainingVisits
      rateGramPerS
      feedTimeS
      feedGrams
      cumulativeFeedTimeS
      cumulativeFeedGrams
    }
  }
`;
