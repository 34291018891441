import React from "react";

import { Vertical } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { FormTextInput } from "shared/components/form/FormTextInput";

import { FormState } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";

import { lengthIsGreaterThanValidator } from "shared/validators/lengthIsGreaterThanValidator";

import { passwordsMustBeTheSameValidator } from "shared/validators/passwordsMustBeTheSameValidator";

interface Props {
  onSubmit: (newPassword: string, verificationCode: string) => void;
}

export const NewPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const onSubmitHandler = ({ password, verificationCode }: FormState) => {
    onSubmit(password.value, verificationCode.value);
  };

  return (
    <Form
      title="New password"
      onSubmit={onSubmitHandler}
      submitButtonText="Change"
    >
      <Vertical width={300} spacing={15}>
        <FormTextInput
          isPassword
          label="Verification code"
          name="verificationCode"
          validators={[isMandatory]}
        />

        <FormTextInput
          isPassword
          label="Password"
          name="password"
          validators={[isMandatory, lengthIsGreaterThanValidator(10)]}
        />

        <FormTextInput
          isPassword
          label="Repeat password"
          name="repetition"
          validators={[passwordsMustBeTheSameValidator]}
        />
      </Vertical>
    </Form>
  );
};
