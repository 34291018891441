import React, { FC } from "react";

import { stylesheet } from "typestyle";
import { Horizontal, Stretch, StretchSpacer, Vertical } from "gls/lib";

import { Icon } from "shared/components/icon/icon";
import { FormValues } from "shared/hooks/useFeedingDataApi";
import { downloadCsv } from "shared/utils/csvUtils";
import { Table, TableColumn } from "shared/components/table/Table";
import { COLORS } from "shared/variables/colors";

interface Props {
  data: any[] | undefined;
  formValues: FormValues | undefined;
}

export const FeedingDataApiTable: FC<Props> = ({ data, formValues }) => {
  const onDownloadCsvClick = () => {
    const day = `day_${formValues?.day}`;
    const siteId = `site_${formValues?.siteId}`;
    const cageId = `cage_${formValues?.cageId}`;

    downloadCsv({
      dataPoints: data as any[],
      fileName: `feeding-data--${siteId}-${cageId}-${day}.csv`,
    });
  };

  return data ? (
    <Vertical>
      <Horizontal className={classNames.tableHeader}>
        <Stretch className={classNames.numberOfRows}>
          {data?.length} row{data.length === 1 ? "" : "s"}
        </Stretch>

        <StretchSpacer />

        <Stretch horizontalAlign="right" onClick={onDownloadCsvClick}>
          <Horizontal className={classNames.downloadCsv} verticalAlign="center">
            <Icon icon="download" color={COLORS.light1} />
            Download CSV
          </Horizontal>
        </Stretch>
      </Horizontal>

      <Table
        data={data}
        columns={columns}
        isLoading={false}
        onRowClick={() => {}}
      />
    </Vertical>
  ) : null;
};

const classNames = stylesheet({
  numberOfRows: {
    color: COLORS.light2,
  },
  downloadCsv: {
    cursor: "pointer",
    color: COLORS.light4,
    $nest: {
      "& svg": {
        width: "12px !important",
        marginTop: 3,
        marginRight: 10,
      },
    },
  },
  tableHeader: {
    paddingTop: 40,
  },
});

const columns: TableColumn[] = [
  { accessor: "startTime", Header: "startTime", align: "center" },
  { accessor: "endTime", Header: "endTime", align: "center" },
  {
    accessor: "currentVisitNumber",
    Header: "currentVisitNumber",
    align: "center",
  },
  { accessor: "remainingVisits", Header: "remainingVisits", align: "center" },
  { accessor: "rateGramPerS", Header: "rateGramPerS", align: "center" },
  { accessor: "feedTimeS", Header: "feedTimeS", align: "center" },
  { accessor: "feedGrams", Header: "feedGrams", align: "center" },
  {
    accessor: "cumulativeFeedTimeS",
    Header: "cumulativeFeedTimeS",
    align: "center",
  },
  {
    accessor: "cumulativeFeedGrams",
    Header: "cumulativeFeedGrams",
    align: "center",
  },
];
