import React, { FC } from "react";

import {
  from,
  HttpLink,
  ApolloLink,
  fromPromise,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { env } from "../env/env";
import { onError } from "@apollo/client/link/error";

import {
  getIdToken,
  refreshIdTokenForThirdPartyUser,
} from "shared/utils/jwtUtils";

const httpLink = new HttpLink({
  uri: `${env.common.thirdPartyApi}/v1/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getIdToken()}` || null,
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (error.extensions?.exception.status === 401) {
        return fromPromise(
          refreshIdTokenForThirdPartyUser().then(() => {
            operation.setContext({
              headers: {
                authorization: `Bearer ${getIdToken()}` || null,
              },
            });

            return forward(operation);
          }),
        );
      }
    });
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([authMiddleware, errorLink, httpLink]),
});

export const ApolloClientProvider: FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
