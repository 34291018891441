import React from "react";
import { style } from "typestyle";
import logo from "assets/images/logo.png";
import { percent } from "csx";

export const HeaderLogo = () => {
  return (
    <img
      className={style({ height: percent(100), padding: 5, paddingRight: 25 })}
      src={logo}
    />
  );
};
