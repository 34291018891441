import React, { FC, ReactElement } from "react";
import { stylesheet } from "typestyle";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

interface Props {
  label?: string | ReactElement;
  isRequired?: boolean;
}

export const FormInputLabel: FC<Props> = ({
  label,
  isRequired,
}): ReactElement => {
  return (
    <>
      {label && (
        <label className={classNames.label}>
          {label}
          {isRequired && "*"}
        </label>
      )}
    </>
  );
};

const classNames = stylesheet({
  label: {
    color: COLORS.light2,
    fontSize: SIZES.fontSize1,
    marginBottom: "0.35rem",
  },
});
