import { FormState, FormValidator } from "../types/formTypes";

export const getValidationErrors = (
  validators: FormValidator[] | undefined,
  ...values: [any, FormState]
): string[] => {
  return (validators ?? [])
    .filter((validator) => !validator.isValid(...values))
    .map((validator) => validator.error);
};
