import { FormValidator } from "../types/formTypes";

export const lengthIsGreaterThanValidator = (length: number): FormValidator => {
  return {
    error: `Value must have a length greater than ${length} characters.`,
    isValid: (value: any | null) => {
      return value?.length > length;
    },
  };
};
