import React from "react";

import { stylesheet } from "typestyle";
import { Content, Horizontal, Stretch, Vertical } from "gls/lib";

import {
  FC,
  useRef,
  useMemo,
  useState,
  useEffect,
  ReactElement,
  createContext,
} from "react";

import { Button } from "../button/Button";

import {
  FormState,
  FormController,
  FormInputState,
} from "../../types/formTypes";

import { SIZES } from "../../variables/sizes";

interface Props {
  title?: string;
  onSubmit?: (state: FormState) => void;
  onChange?: (state: FormState) => void;
  submitButtonText?: string;
  submitButtonIsDisabled?: boolean;
  clearButtonText?: string;
}

export const FormContext = createContext<FormController>({} as any);

export const Form: FC<Props> = ({
  title,
  children,
  onSubmit,
  onChange,
  submitButtonText,
  submitButtonIsDisabled,
  clearButtonText,
}): ReactElement => {
  const formIsInitialized = useRef<boolean>(false);
  const [formState, setFormState] = useState<FormState>({});

  const isValid = useMemo(() => {
    if (formIsInitialized.current) {
      return Object.values(formState).every((input) => input.isValid);
    }
  }, [formState]);

  useEffect(() => {
    const someFieldIsDirty = Object.values(formState).some(
      (input) => input.isDirty,
    );

    if (someFieldIsDirty) {
      onChange?.(formState);
    }
  }, [formState]);

  useEffect(() => {
    setFormState({
      ...formState,
    });

    formIsInitialized.current = true;
  }, []);

  const clearFormState = () => {
    setFormState({});
  };

  return (
    <FormContext.Provider
      value={[
        formState,
        (name: string, state: Partial<FormInputState>) => {
          if (!formIsInitialized.current) {
            formState[name] = {
              ...formState[name],
              ...state,
            };
          } else {
            setFormState({
              ...formState,
              [name]: {
                ...formState[name],
                ...state,
              },
            });
          }
        },
      ]}
    >
      <Vertical>
        {title && <Stretch className={classNames.title}>{title}</Stretch>}

        {children}

        {(clearButtonText || submitButtonText) && (
          <Horizontal horizontalAlign="right">
            {clearButtonText && (
              <Content horizontalAlign="right">
                <Button
                  onClick={() => {
                    clearFormState();
                  }}
                >
                  {clearButtonText}
                </Button>
              </Content>
            )}
            {submitButtonText && (
              <Content horizontalAlign="right">
                <Button
                  onClick={() => isValid && onSubmit?.(formState)}
                  isDisabled={!isValid || submitButtonIsDisabled}
                >
                  {submitButtonText}
                </Button>
              </Content>
            )}
          </Horizontal>
        )}
      </Vertical>
    </FormContext.Provider>
  );
};

const classNames = stylesheet({
  title: {
    fontSize: SIZES.fontSize3,
  },
});
