import React from "react";

import { Vertical } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { FormTextInput } from "shared/components/form/FormTextInput";

import { FormState } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";

interface Props {
  onSubmit: (code: string) => void;
}

export const LoginOTPForm: React.FC<Props> = ({ onSubmit }) => {
  const onSubmitHandler = ({ code }: FormState) => {
    onSubmit(code.value);
  };

  return (
    <Form
      title="OTP verification"
      onSubmit={onSubmitHandler}
      submitButtonText="Login"
    >
      <Vertical width={300} spacing={15}>
        <FormTextInput
          label="OTP code"
          name="code"
          validators={[isMandatory]}
        />
      </Vertical>
    </Form>
  );
};
