import React, { FC } from "react";
import moment, { Moment } from "moment";

import { FormDatePickerInput } from "shared/components/form/FormDatePickerInput";

export const AggregatedDataApiDayInput: FC = () => {
  const isOutsideRange = (date: Moment) => {
    return date.isAfter(moment().subtract(1, "day"));
  };

  return (
    <FormDatePickerInput
      name="day"
      label="Day of the month"
      placeholder="Select the day"
      isOutsideRange={isOutsideRange}
    />
  );
};
