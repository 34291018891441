import { isNil } from "shared/utils/nilUtils";
import { isString } from "shared/utils/stringUtils";
import { FormValidator } from "shared/types/formTypes";

export const isMandatory: FormValidator = {
  error: "Field is mandatory",
  isValid: (value: any) => {
    return isString(value) ? value.length > 0 : !isNil(value);
  },
};
