import React, { FC, useState } from "react";
import axios from "axios";

import { env } from "env/env";
import { Docs } from "shared/types/docsType";
import { useUser } from "shared/hooks/useUser";
import { getIdToken } from "shared/utils/jwtUtils";
import { useFeedback } from "shared/hooks/useFeedback";

export const DocsContext = React.createContext<{
  docs: Docs | undefined;
  fetchDocs: () => void;
  resetDocs: () => void;
}>({
  docs: undefined,
  fetchDocs: () => {},
  resetDocs: () => {},
});

export const DocsContextProvider: FC = ({ children }) => {
  const [docs, setDocs] = useState<Docs>();
  const [fetching, setFetching] = useState<boolean>(false);

  const { user } = useUser();
  const { showNegativeFeedback } = useFeedback();

  const fetchDocs = () => {
    if (!fetching && !docs) {
      setFetching(true);

      const api = user?.isManager
        ? env.managers.documentationApi
        : env.thirdPartyUsers.documentationApi;

      axios
        .get(api, {
          headers: {
            Authorization: getIdToken(),
          },
        })
        .then((response) => response.data)
        .then(setDocs)
        .catch(() => {
          showNegativeFeedback("Couldn't load documentations.");
        })
        .finally(() => setFetching(false));
    }
  };

  const resetDocs = () => {
    setDocs(undefined);
  };

  return (
    <DocsContext.Provider value={{ fetchDocs, docs, resetDocs }}>
      {children}
    </DocsContext.Provider>
  );
};
