import Select from "react-select";
import React, { FC } from "react";

import { Vertical } from "gls/lib";
import { stylesheet } from "typestyle";

import { FormInputLabel } from "./FormInputLabel";
import { FormInputErrors } from "./FormInputErrors";
import { InputProps, useInput } from "../../hooks/useInput";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

interface SimpleSelectProps {
  isMultiple: false;
  initialValue?: FormSelectOption[];
}

interface MultipleSelectProps {
  isMultiple: true;
  initialValue?: FormSelectOption[];
}

type Props = {
  label?: string;
  options: FormSelectOption[];
  isMultiple?: boolean;
} & InputProps<(FormSelectOption | FormSelectOption)[]> &
  (SimpleSelectProps | MultipleSelectProps);

export const FormSelectInput: FC<Props> = (props) => {
  const input = useInput({ ...props });

  return (
    <Vertical width="100%" spacing={0}>
      <FormInputLabel {...props} />

      <Select
        name={props.name}
        options={props.options}
        isMulti={props.isMultiple}
        className={classNames.input}
        isClearable={false}
        defaultValue={input.value}
        classNamePrefix="select"
        theme={{
          borderRadius: SIZES.borderRadius,
          spacing: {
            baseUnit: 4,
            controlHeight: 0,
            menuGutter: 1,
          },
          colors: {
            neutral10: COLORS.dark3,
            neutral20: COLORS.dark3,
            neutral60: COLORS.light3,
            neutral80: COLORS.light4,
            primary: COLORS.primary,
            primary25: COLORS.dark3,
          },
        }}
        onBlur={input.onBlurHandler}
        onChange={input.onChangeHandler as any}
      />

      <FormInputErrors {...input} />
    </Vertical>
  );
};

const classNames = stylesheet({
  input: {
    fontSize: SIZES.fontSize2,
    $nest: {
      ".select__placeholder": {
        color: COLORS.light2,
      },
      ".select__control": {
        cursor: "pointer",
      },
      ".select__control--menu-is-open": {
        boxShadow: "none",
      },
      ".select__option": {
        background: COLORS.dark1,
        color: COLORS.light2,
        cursor: "pointer",
      },
      ".select__menu-notice--no-options": {
        background: COLORS.dark1,
      },
      ".select__option--is-focused": {
        color: COLORS.light4,
        background: COLORS.dark3,
      },
      ".select__option--is-selected": {
        background: COLORS.primary,
        color: COLORS.light4,
      },
    },
  },
});

export interface FormSelectOption {
  value: any;
  label: string;
}
