import { ElementType, FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { Path } from "../../types/pathType";
import { useUser } from "../../hooks/useUser";
import { useFeedback } from "../../hooks/useFeedback";

type Props = RouteProps & {
  component: ElementType;
  requiredPermission?: "hasRealtimeAccess" | "hasCloudAccess";
};

export const PrivateRoute: FC<Props> = ({
  component: Component,
  requiredPermission,
  ...rest
}) => {
  const { user, isLoading } = useUser();
  const { showNegativeFeedback } = useFeedback();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return;
        }

        if (!user) {
          return <Redirect to={Path.LOGIN} />;
        }

        if (
          requiredPermission &&
          user.hasOwnProperty(requiredPermission) &&
          !user[requiredPermission]
        ) {
          // showNegativeFeedback("User does not have permissions to view this API documentation")
          return <Redirect to={Path.UNAUTHORIZED} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};
