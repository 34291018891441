import { Content, Horizontal, Vertical } from "gls/lib";
import React, { createContext, FC } from "react";
import { classes, style } from "typestyle";
import { COLORS } from "../../variables/colors";
import { SIZES } from "../../variables/sizes";

interface TabsProps {
  activeTab: string;
  tabs: string[];
  onChange: (tab: string) => void;
}

export const tabsContext = createContext<TabsProps>({
  activeTab: "",
  tabs: [],
  onChange: () => {},
});

export const Tabs: FC<TabsProps> = ({
  children,
  activeTab,
  tabs,
  onChange,
}) => {
  return (
    <tabsContext.Provider value={{ activeTab, tabs, onChange }}>
      <Vertical height="100%">
        <Horizontal>
          {(tabs || []).map((tab) => (
            <Content
              onClick={() => onChange(tab)}
              className={classes(tabStyle, tab === activeTab && "active")}
            >
              {tab}
            </Content>
          ))}
        </Horizontal>
        {children}
      </Vertical>
    </tabsContext.Provider>
  );
};

const tabStyle = style({
  cursor: "pointer",
  fontSize: SIZES.fontSize3,
  color: COLORS.light2,
  padding: 15,
  textDecoration: "none",
  borderBottom: "2px solid transparent",
  $nest: {
    "&.active": {
      color: COLORS.light4,
      borderBottom: `2px solid ${COLORS.light4}`,
    },
  },
});
