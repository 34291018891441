import { useToasts } from "react-toast-notifications";

interface UseFeedbackResponse {
  showPositiveFeedback: (message: string) => void;
  showNegativeFeedback: (message: string) => void;
}

export const useFeedback = (): UseFeedbackResponse => {
  const { addToast } = useToasts();

  return {
    showPositiveFeedback: (message: string) => {
      addToast(message, {
        appearance: "success",
      });
    },
    showNegativeFeedback: (message: string) => {
      addToast(message, {
        appearance: "error",
      });
    },
  };
};
