import { PERMISSIONS } from "@observe-tech/marlin-utils";

export enum AccountStatus {
  CONFIRMED,
  FORCE_CHANGE_PASSWORD,
}

export enum AuthFlow {
  USER_SRP_AUTH = "USER_SRP_AUTH",
}

export enum ChallengeName {
  OTP_SETUP = "MFA_SETUP",
  OTP_LOGIN = "SOFTWARE_TOKEN_MFA",
  PASSWORD_LOGIN = "PASSWORD_LOGIN",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export type Challenge =
  | OtpSetupChallenge
  | OtpLoginChallenge
  | PasswordLoginChallenge
  | NewPasswordRequiredChallenge;

export interface OtpSetupChallenge {
  name: ChallengeName.OTP_SETUP;
  secret: string;
}

export interface OtpLoginChallenge {
  name: ChallengeName.OTP_LOGIN;
}

export interface PasswordLoginChallenge {
  name: ChallengeName.PASSWORD_LOGIN;
}

export interface NewPasswordRequiredChallenge {
  name: ChallengeName.NEW_PASSWORD_REQUIRED;
}

export enum JwtAttribute {
  EMAIL = "email",
  GROUPS = "cognito:groups",
  USERNAME = "cognito:username",
  PERMISSIONS = "custom:permissions",
}

export interface Jwt {
  exp: number;
  [JwtAttribute.EMAIL]: string;
  [JwtAttribute.GROUPS]: string[];
  [JwtAttribute.USERNAME]: string;

  // May be or not depending on if the user is a manager or not
  [JwtAttribute.PERMISSIONS]?: string;
}

// TODO: fetch from API / share with repo
export const REALTIME_PERMISSION_NAMES = Object.keys(PERMISSIONS);

export const CLOUD_PERMISSION_NAMES = [
  "READ_FEEDING_DATA",
  "READ_AGGREGATED_DATA",
];
// TODO: fetch from API
export const LIVE_PERMISSION_NAMES = [
  "SEND_COMMANDS",
  "READ_MANAGER_API",
  "READ_REAL_TIME_DATA",
  "EDIT_REAL_TIME_DATA",
];

export const PERMISSION_NAMES = REALTIME_PERMISSION_NAMES.concat(
  CLOUD_PERMISSION_NAMES,
);
