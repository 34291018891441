export const COLORS = {
  dark1: "#262936",
  dark2: "#2b2f3d",
  dark3: "#313648",
  modalBg: "#171920e8",
  light1: "#8a92b2",
  light2: "#6b7086",
  light3: "#444a66",
  light4: "#c3c5d1",
  error: "#db3f3f",
  positive: "#78a303",
  primary: "#2196f3",
  primaryHover: "#64baff",
};
