import React, { FC } from "react";

import { Stretch } from "gls/lib";
import { calc, px, viewHeight } from "csx";

import { Header } from "shared/components/header/Header";

import { SIZES } from "shared/variables/sizes";

export const PageContainer: FC = ({ children }) => (
  <>
    <Header />

    <Stretch
      height={calc(`${viewHeight(100)} - ${px(SIZES.headerHeight)}`)}
      horizontalAlign="center"
      sizing={1}
      padding={[20, 0]}
    >
      {children}
    </Stretch>
  </>
);
