import { Permission } from "aws-sdk/clients/appstream";
import { bool } from "aws-sdk/clients/signer";
import jwtDecode from "jwt-decode";

import { useEffect, useState } from "react";

import { isManager } from "shared/utils/userUtils";
import {
  CLOUD_PERMISSION_NAMES,
  Jwt,
  JwtAttribute,
  REALTIME_PERMISSION_NAMES,
} from "../types/cognitoTypes";
import { getIdToken, refreshIdToken } from "../utils/jwtUtils";

interface UseUserResponse {
  user: User | undefined;
  isLoading: boolean;
}

export const useUser = (): UseUserResponse => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const jwt: Jwt | null = getJwt();

    if (jwt) {
      const isExpired: boolean = Date.now() > jwt.exp * 1000;

      if (isExpired) {
        refreshIdToken(getUser().isManager)
          .then(() => setUser(getUser()))
          .finally(() => setIsLoading(false));
      } else {
        setUser(getUser());
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  return { user, isLoading };
};

const getUser = (): User => {
  const jwt: Jwt = getJwt() as any;
  const customPermissions: Record<
    Permission,
    { exp: string; active: boolean }
  > = JSON.parse(jwt[JwtAttribute.PERMISSIONS]).data ?? {};
  const permissions = Object.keys(customPermissions).filter((key) => {
    return Date.now() < new Date(customPermissions[key].exp).getTime();
  });
  const username = jwt[JwtAttribute.PERMISSIONS]
    ? jwt[JwtAttribute.USERNAME]
    : jwt[JwtAttribute.EMAIL];

  const user = {
    username,
    isManager: isManager(username),
    permissions: permissions,
    hasRealtimeAccess: permissions.some((item) =>
      REALTIME_PERMISSION_NAMES.includes(item),
    ),
    hasCloudAccess: permissions.some((item) =>
      CLOUD_PERMISSION_NAMES.includes(item),
    ),
  };
  return user;
};

const getJwt = (): Jwt | null => {
  const idToken = getIdToken();

  try {
    return jwtDecode(idToken as string) as any;
  } catch (error) {
    return null;
  }
};

interface User {
  username: string;
  isManager: boolean;
  permissions: string[];
  hasRealtimeAccess: boolean;
  hasCloudAccess: boolean;
}
