const getDataPointsAsCsv = (dataPoints: object[]): string => {
  const body = dataPoints.map(fromDataPointToCsvRow).join("\n");

  return `${getCsvHeadersNames(dataPoints)}\n${body}`;
};

const getCsvHeadersNames = (dataPoints: object[]): string => {
  return dataPoints.length > 0 ? Object.keys(dataPoints[0]).join(",") : "";
};

const fromDataPointToCsvRow = (dataPoint: object): string => {
  return Object.values(dataPoint).join(",");
};

export const downloadCsv = (params: {
  fileName: string;
  dataPoints: object[];
}): void => {
  const csvData = getDataPointsAsCsv(params.dataPoints);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

  const linkEl = document.createElement("a");
  const url = URL.createObjectURL(blob);
  linkEl.setAttribute("href", url);
  linkEl.setAttribute("download", params.fileName);
  linkEl.style.visibility = "hidden";
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};
