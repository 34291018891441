import axios from "axios";
import React, { FC, useEffect, useState } from "react";

import { env } from "env/env";
import { getIdToken } from "shared/utils/jwtUtils";
import { useFeedback } from "shared/hooks/useFeedback";

import {
  FormSelectInput,
  FormSelectOption,
} from "shared/components/form/FormSelectInput";

export const AggregatedDataApiSiteInput: FC = () => {
  const { showNegativeFeedback } = useFeedback();
  const [sitesOptions, setSitesOptions] = useState<FormSelectOption[]>([]);

  useEffect(() => {
    axios
      .get(`${env.common.thirdPartyApi}/sites`, {
        headers: {
          Authorization: getIdToken(),
        },
      })
      .then((response) => response.data)
      .then((sites) =>
        sites.map((site) => ({
          value: site.id,
          label: site.name,
        })),
      )
      .then(setSitesOptions)
      .catch(() => showNegativeFeedback("Couldn't load sites."));
  }, []);

  return (
    <FormSelectInput
      name="siteId"
      label="Site"
      options={sitesOptions}
      isMultiple={false}
    />
  );
};
