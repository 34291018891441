import React from "react";

import { Row, Cell } from "react-table";
import { classes, style, stylesheet } from "typestyle";
import { TableBodyProps } from "./TableBody";

import { COLORS } from "../../variables/colors";
import { Horizontal } from "gls/lib";
import { percent, px } from "csx";

export const TableBodyWithData = <T extends {}>({
  columns,
  rows,
  onRowClick,
  prepareRow,
  getTableBodyProps,
}: TableBodyProps<T>) => {
  const renderCell = (cell: Cell<T, any>) => {
    const column = columns.find((c) => c.Header === cell.column?.Header);
    const widget = column?.widget;

    return (
      <Horizontal
        sizing="stretch"
        horizontalAlign={column?.align || "center"}
        className={style({ textAlign: column?.align || "center" })}
      >
        {widget ? widget({ value: cell.value }) : cell.value}
      </Horizontal>
    );
  };

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row: Row<T>) => {
        prepareRow(row);

        return (
          <tr
            {...row.getRowProps()}
            className={classes(classNames.row, onRowClick && "clickable")}
            onClick={() => onRowClick?.({ ...row.original })}
          >
            {row.cells.map((cell: Cell<T>) => {
              return (
                <td {...cell.getCellProps()} className={classNames.cell}>
                  {renderCell(cell)}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

const classNames = stylesheet({
  row: {
    $nest: {
      "&:hover.clickable": {
        cursor: "pointer",
        background: COLORS.dark2,
      },
      "&:not(:first-child)::after": {
        content: `""`,
        display: "block",
        width: percent(100),
        background: COLORS.dark3,
        height: px(1),
        position: "absolute",
        left: 0,
      },
    },
  },
  cell: {
    padding: "0.6rem",
  },
});
