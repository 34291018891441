import React from "react";

import { stylesheet } from "typestyle";
import { Stretch, Vertical } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { FormTextInput } from "shared/components/form/FormTextInput";

import { FormState } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";

import { lengthIsGreaterThanValidator } from "shared/validators/lengthIsGreaterThanValidator";

import { passwordsMustBeTheSameValidator } from "shared/validators/passwordsMustBeTheSameValidator";

import { SIZES } from "shared/variables/sizes";
import { COLORS } from "shared/variables/colors";

interface Props {
  onSubmit: (password: string) => void;
}

export const NewPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const onSubmitHandler = ({ password }: FormState) => {
    onSubmit(password.value);
  };

  return (
    <Form
      title="Change password"
      onSubmit={onSubmitHandler}
      submitButtonText="Change"
    >
      <Vertical width={300} spacing={15}>
        <Stretch className={classNames.info}>
          You need to set a new password. Save it properly as this is the one
          you will be using to login.
        </Stretch>

        <FormTextInput
          isPassword
          label="Password"
          name="password"
          validators={[isMandatory, lengthIsGreaterThanValidator(10)]}
        />

        <FormTextInput
          isPassword
          label="Repeat password"
          name="repetition"
          validators={[passwordsMustBeTheSameValidator]}
        />
      </Vertical>
    </Form>
  );
};

const classNames = stylesheet({
  info: {
    maxWidth: SIZES.input,
    background: COLORS.dark2,
    borderRadius: SIZES.borderRadius,
    padding: "0.65rem",
    textAlign: "center",
    color: COLORS.light2,
    border: `1px solid ${COLORS.dark3}`,
    fontSize: "15px",
    lineHeight: "1.35rem",
  },
});
