import React from "react";
import { Vertical } from "gls/lib";

import { FC } from "react";

import { PageContainer } from "shared/components/page-container/PageContainer";

export const UnAuthorizedPage: FC = () => {
  return (
    <PageContainer>
      <Vertical
        spacing={5}
        width="100%"
        height="100%"
        padding={[0, 20]}
        horizontalAlign="center"
      >
        <p>
          User does not have the permissions required to view documentation.
        </p>
        <p>
          Please contact your administrator to get access to this API for your
          user.
        </p>
      </Vertical>
    </PageContainer>
  );
};
