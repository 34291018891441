import React from "react";

import { stylesheet } from "typestyle";
import { useHistory } from "react-router-dom";
import { Horizontal, Vertical, StretchSpacer, Content } from "gls/lib";

import { Path } from "shared/types/pathType";
import { Form } from "shared/components/form/Form";
import { FormState } from "shared/types/formTypes";
import { isMandatory } from "shared/validators/isMandatoryValidator";
import { FormTextInput } from "shared/components/form/FormTextInput";

import { SIZES } from "shared/variables/sizes";
import { COLORS } from "shared/variables/colors";

interface Props {
  onSubmit: (username: string, password: string) => void;
}

export const LoginPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const history = useHistory();

  const onSubmitHandler = ({ username, password }: FormState) => {
    onSubmit(username.value, password.value);
  };

  return (
    <Form title="Login" onSubmit={onSubmitHandler} submitButtonText="Login">
      <Vertical width={300} spacing={15}>
        <FormTextInput
          label="Username"
          name="username"
          validators={[isMandatory]}
        />

        <FormTextInput
          isPassword
          label={
            <Horizontal>
              <Content>Password</Content>
              <StretchSpacer />
              <Content
                className={classNames.passwordLabel}
                onClick={() => history.push(Path.FORGOT_PASSWORD)}
              >
                Did you forget it?
              </Content>
            </Horizontal>
          }
          name="password"
          validators={[isMandatory]}
        />
      </Vertical>
    </Form>
  );
};

const classNames = stylesheet({
  passwordLabel: {
    color: COLORS.light2,
    cursor: "pointer",
    fontSize: SIZES.fontSize1,
    horizontalAlign: "right",
    $nest: {
      "&:hover": {
        color: COLORS.light1,
      },
    },
  },
});
