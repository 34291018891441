import React, { FC } from "react";

import { Stretch } from "gls/lib";
import { calc, rgba, viewHeight } from "csx";

import { Loader } from "./Loader";
import { stylesheet } from "typestyle";

export const PageLoader: FC = () => (
  <>
    <Stretch
      height={calc(`${viewHeight(100)}`)}
      className={classNames.pageLoader}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Loader />
    </Stretch>
  </>
);

const classNames = stylesheet({
  pageLoader: {
    background: rgba(0, 0, 0, 0.45).toString(),
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
  },
});
