import React from "react";

import {
  Row,
  TableBodyPropGetter,
  TableBodyProps as RTTableBodyProps,
} from "react-table";
import { TableColumn } from "./Table";

import { TableBodyLoading } from "./TableBodyLoading";
import { TableBodyWithData } from "./TableBodyWithData";
import { TableBodyWithoutData } from "./TableBodyWithoutData";

export interface TableBodyProps<T extends object> {
  rows: Row<T>[];
  columns: TableColumn<T>[];
  onRowClick?: (row: T) => void;
  prepareRow: (row: Row<T>) => void;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T>) => RTTableBodyProps;
}

export const TableBody = <T extends {}>(
  props: TableBodyProps<T> & { isLoading: boolean },
) => {
  const thereIsData = (props.rows || []).length > 0;

  return (
    <>
      {!props.isLoading && thereIsData && <TableBodyWithData {...props} />}

      {!props.isLoading && !thereIsData && <TableBodyWithoutData />}

      {props.isLoading && <TableBodyLoading />}
    </>
  );
};
