import React, { FC, useEffect, useState } from "react";

import { PageLoader } from "shared/components/loader/PageLoader";
import { useFeedback } from "shared/hooks/useFeedback";
import { AggregatedDataApiForm } from "./AggregatedDataApiForm";
import { AggregatedDataApiTable } from "./AggregatedDataApiTable";

import {
  FormValues,
  useAggregatedDataApi,
} from "shared/hooks/useAggregatedDataApi";

export const AggregatedDataApi: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>();

  const { showNegativeFeedback } = useFeedback();
  const { getData, data, errorMessage } = useAggregatedDataApi();

  useEffect(() => {
    if (formValues) {
      setIsLoading(true);
      getData(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (errorMessage) {
      setIsLoading(false);
      showNegativeFeedback(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <AggregatedDataApiForm onFormValuesChange={setFormValues} />

      <AggregatedDataApiTable data={data} formValues={formValues} />

      {isLoading && <PageLoader />}
    </>
  );
};
