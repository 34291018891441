import { useCallback, useMemo, useRef } from "react";

import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import { getCognitoParams } from "shared/utils/cognitoUtils";
import { setIdToken, setRefreshToken } from "../utils/jwtUtils";

import {
  Challenge,
  ChallengeName,
  OtpSetupChallenge,
  PasswordLoginChallenge,
} from "../types/cognitoTypes";

export const useLogin = (): any => {
  const context = useRef<LoginContext>({});

  const onMfaSetup = useCallback(() => {
    return new Promise<OtpSetupChallenge>((resolve, reject) => {
      context.current.cognitoUser?.associateSoftwareToken({
        associateSecretCode: (secret: string) => {
          resolve({
            name: ChallengeName.OTP_SETUP,
            secret,
          });
        },
        onFailure: reject,
      });
    });
  }, [context]);

  return useMemo(() => {
    return {
      loginWithPassword: (
        username: string,
        password: string,
      ): Promise<Challenge | string> => {
        const userPool = new CognitoUserPool({
          ClientId: getCognitoParams(username).clientId,
          UserPoolId: getCognitoParams(username).userPoolId,
        });

        context.current.cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        });

        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });

        return new Promise<Challenge | string>((resolve, reject) => {
          context.current.cognitoUser?.authenticateUser(authenticationDetails, {
            /**
             * It will always pass through "totpRequired", "mfaSetup"
             * or "newPasswordRequired" given that there is a second factor
             * of authentication.
             */
            onSuccess: (session: CognitoUserSession) => {
              setIdToken(session.getIdToken().getJwtToken());
              setRefreshToken(session.getRefreshToken().getToken());

              resolve(username);
            },
            onFailure: reject,
            totpRequired: () => {
              resolve({ name: ChallengeName.OTP_LOGIN });
            },
            mfaSetup: () => {
              onMfaSetup().then(resolve).catch(reject);
            },
            newPasswordRequired: () => {
              resolve({ name: ChallengeName.NEW_PASSWORD_REQUIRED });
            },
          });
        });
      },
      loginWithOTP: (code: string): Promise<string | undefined> => {
        return new Promise((resolve, reject) => {
          context.current.cognitoUser?.sendMFACode(
            code,
            {
              onFailure: reject,
              onSuccess: (session: CognitoUserSession) => {
                setIdToken(session.getIdToken().getJwtToken());
                setRefreshToken(session.getRefreshToken().getToken());

                context.current.cognitoUser?.getUserAttributes(
                  (error: any, attributes?: CognitoUserAttribute[]) => {
                    if (error) {
                      reject(error);
                    } else {
                      resolve(getEmailAttribute(attributes));
                    }
                  },
                );
              },
            },
            ChallengeName.OTP_LOGIN,
          );
        });
      },
      setNewPassword: (newPassword: string): Promise<Challenge> => {
        return new Promise((resolve, reject) => {
          context.current.cognitoUser?.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: () => {
                resolve({ name: ChallengeName.PASSWORD_LOGIN });
              },
              onFailure: reject,
              mfaSetup: () => {
                onMfaSetup().then(resolve).catch(reject);
              },
            },
          );
        });
      },
      setUpOTPClient: (otp: string): Promise<PasswordLoginChallenge> => {
        return new Promise((resolve, reject) => {
          context.current.cognitoUser?.verifySoftwareToken(otp, "", {
            onSuccess: () => {
              resolve({ name: ChallengeName.PASSWORD_LOGIN });
            },
            onFailure: reject,
          });
        });
      },
    };
  }, [context]);
};

interface LoginContext {
  cognitoUser?: CognitoUser;
}

const getEmailAttribute = (
  attributes?: CognitoUserAttribute[],
): string | undefined => {
  return attributes?.find((attribute) => attribute.Name === "email")?.Value;
};
