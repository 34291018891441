import React, { FC } from "react";

import { stylesheet } from "typestyle";
import { Horizontal, Stretch, StretchSpacer, Vertical } from "gls/lib";

import { Icon } from "shared/components/icon/icon";
import { FormValues } from "shared/hooks/useAggregatedDataApi";
import { downloadCsv } from "shared/utils/csvUtils";
import { Table, TableColumn } from "shared/components/table/Table";
import { COLORS } from "shared/variables/colors";

interface Props {
  data: any[] | undefined;
  formValues: FormValues | undefined;
}

export const AggregatedDataApiTable: FC<Props> = ({ data, formValues }) => {
  const onDownloadCsvClick = () => {
    const day = `day_${formValues?.day}`;
    const hour = `hour_${formValues?.hour}`;
    const siteId = `site_${formValues?.siteId}`;
    const cageId = `cage_${formValues?.cageId}`;

    downloadCsv({
      dataPoints: data as any[],
      fileName: `aggregated-data--${siteId}-${cageId}-${day}-${hour}.csv`,
    });
  };

  return data ? (
    <Vertical>
      <Horizontal className={classNames.tableHeader}>
        <Stretch className={classNames.numberOfRows}>
          {data?.length} row{data.length === 1 ? "" : "s"}
        </Stretch>

        <StretchSpacer />

        <Stretch horizontalAlign="right" onClick={onDownloadCsvClick}>
          <Horizontal className={classNames.downloadCsv} verticalAlign="center">
            <Icon icon="download" color={COLORS.light1} />
            Download CSV
          </Horizontal>
        </Stretch>
      </Horizontal>

      <Table
        data={data}
        columns={columns}
        isLoading={false}
        onRowClick={() => {}}
      />
    </Vertical>
  ) : null;
};

const classNames = stylesheet({
  numberOfRows: {
    color: COLORS.light2,
  },
  downloadCsv: {
    cursor: "pointer",
    color: COLORS.light4,
    $nest: {
      "& svg": {
        width: "12px !important",
        marginTop: 3,
        marginRight: 10,
      },
    },
  },
  tableHeader: {
    paddingTop: 40,
  },
});

const columns: TableColumn[] = [
  { accessor: "timeStamp", Header: "timeStamp", align: "center" },
  { accessor: "activity", Header: "activity", align: "center" },
  { accessor: "numOfPellets", Header: "numOfPellets", align: "center" },
  { accessor: "isAlert", Header: "isAlert", align: "center" },
  { accessor: "rateGramPerSec", Header: "rateGramPerSec", align: "center" },
  { accessor: "feedScore", Header: "feedScore", align: "center" },
  { accessor: "closeCamera", Header: "closeCamera", align: "center" },
  { accessor: "schooling", Header: "schooling", align: "center" },
  { accessor: "severity", Header: "severity", align: "center" },
  { accessor: "density", Header: "density", align: "center" },
  { accessor: "speed", Header: "speed", align: "center" },
  { accessor: "balling", Header: "balling", align: "center" },
  { accessor: "visibility", Header: "visibility", align: "center" },
  { accessor: "depth", Header: "depth", align: "center" },
  {
    accessor: "currentVisitnumber",
    Header: "currentVisitnumber",
    align: "center",
  },
  { accessor: "feedTimeS", Header: "feedTimeS", align: "center" },
  { accessor: "feedGrams", Header: "feedGrams", align: "center" },
  {
    accessor: "cumulativeFeedTimeS",
    Header: "cumulativeFeedTimeS",
    align: "center",
  },
  {
    accessor: "cumulativeFeedGrams",
    Header: "cumulativeFeedGrams",
    align: "center",
  },
  { accessor: "OXYGEN_0_6m", Header: "OXYGEN_0_6m", align: "center" },
  { accessor: "OXYGEN_6_12m", Header: "OXYGEN_6_12m", align: "center" },
  { accessor: "OXYGEN_12_20m", Header: "OXYGEN_12_20m", align: "center" },
  { accessor: "SALINITY_0_6m", Header: "SALINITY_0_6m", align: "center" },
  { accessor: "SALINITY_6_12m", Header: "SALINITY_6_12m", align: "center" },
  { accessor: "SALINITY_12_20m", Header: "SALINITY_12_20m", align: "center" },
  { accessor: "SATURATION_0_6m", Header: "SATURATION_0_6m", align: "center" },
  { accessor: "SATURATION_6_12m", Header: "SATURATION_6_12m", align: "center" },
  {
    accessor: "SATURATION_12_20m",
    Header: "SATURATION_12_20m",
    align: "center",
  },
  { accessor: "TEMPERATURE_0_6m", Header: "TEMPERATURE_0_6m", align: "center" },
  {
    accessor: "TEMPERATURE_6_12m",
    Header: "TEMPERATURE_6_12m",
    align: "center",
  },
  {
    accessor: "TEMPERATURE_12_20m",
    Header: "TEMPERATURE_12_20m",
    align: "center",
  },
];
