import React, { FC, ReactElement } from "react";
import { stylesheet } from "typestyle";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

interface Props {
  errors: string[];
  isDirty: boolean;
}

export const FormInputErrors: FC<Props> = ({
  errors,
  isDirty,
}): ReactElement => {
  return (
    <>
      {isDirty &&
        errors.map((error: string, index: number) => (
          <p key={index} className={classNames.error}>
            · {error}
          </p>
        ))}
    </>
  );
};

const classNames = stylesheet({
  error: {
    color: COLORS.light2,
    fontSize: SIZES.fontSize1,
    margin: "0.35rem 0 0 0",
  },
});
