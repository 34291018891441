import { FC, useEffect, useState } from "react";

import { PageLoader } from "shared/components/loader/PageLoader";
import { useFeedback } from "shared/hooks/useFeedback";
import { FeedingDataApiForm } from "./FeedingDataApiForm";
import { FeedingDataApiTable } from "./FeedingDataApiTable";

import { FormValues, useFeedingDataApi } from "shared/hooks/useFeedingDataApi";

export const FeedingDataApi: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>();

  const { showNegativeFeedback } = useFeedback();
  const { getData, data, errorMessage } = useFeedingDataApi();

  useEffect(() => {
    if (formValues) {
      setIsLoading(true);
      getData(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (errorMessage) {
      setIsLoading(false);
      showNegativeFeedback(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <FeedingDataApiForm onFormValuesChange={setFormValues} />

      <FeedingDataApiTable data={data} formValues={formValues} />

      {isLoading && <PageLoader />}
    </>
  );
};
