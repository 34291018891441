import { percent } from "csx";
import React, { FC, useContext } from "react";
import { classes, style } from "typestyle";
import { tabsContext } from "./Tabs";

export const TabContent: FC<{ name: string }> = ({ children, name }) => {
  const { activeTab } = useContext(tabsContext);
  return (
    <div className={classes(activeTab !== name && "hidden", content)}>
      {children}
    </div>
  );
};

const content = style({
  height: percent(100),
  $nest: {
    "&.hidden": {
      display: "none",
    },
  },
});
