import React, { FC } from "react";
import { stylesheet, classes } from "typestyle";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

export type ButtonType = "primary" | "secondary" | "danger";

interface Props {
  type?: ButtonType;
  onClick: () => void;
  className?: string;
  isDisabled?: boolean;
}

export const Button: FC<Props> = ({
  type = "primary",
  onClick,
  children,
  className,
  isDisabled,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={classes(className, classNames[type], classNames.button)}
    >
      {children}
    </button>
  );
};

const classNames = stylesheet({
  button: {
    textAlign: "center",
    padding: "0.75rem 1.5rem",
    border: "none",
    borderRadius: SIZES.borderRadius,
    fontSize: SIZES.fontSize2,
    cursor: "pointer",
    fontWeight: "bolder",
    position: "relative",
    $nest: {
      "&:disabled": {
        opacity: 0.5,
        cursor: "auto",
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
  primary: {
    background: COLORS.primary,
    color: COLORS.dark3,
    border: `1px solid ${COLORS.primary}`,
    "&:active, &:hover": {
      color: COLORS.dark1,
      background: COLORS.primary,
      border: `1px solid ${COLORS.primary}`,
    },
  },
  secondary: {
    background: "none",
    border: `1px solid ${COLORS.light3}`,
    color: COLORS.light2,
    $nest: {
      "&:active, &:hover": {
        background: COLORS.light3,
        color: COLORS.dark1,
      },
    },
  },
  danger: {
    background: "none",
    border: `1px solid ${COLORS.light3}`,
    color: COLORS.light2,
    $nest: {
      "&:active": {
        color: COLORS.light4,
      },
      "&:active, &:hover": {
        background: COLORS.error,
        color: COLORS.light4,
        border: `1px solid ${COLORS.error}`,
      },
    },
  },
});
