import { env } from "../../env/env";
import { CognitoIdentityServiceProvider } from "aws-sdk";

const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_KEY = "refresh_token";

export const getIdToken = (): string | null => {
  return localStorage.getItem(ID_TOKEN_KEY);
};

export const setIdToken = (idToken: string): void => {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
};

export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const removeTokens = (): void => {
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const refreshIdTokenForThirdPartyUser = (): Promise<void> => {
  return refreshIdToken(false);
};

export const refreshIdToken = (isManager: boolean): Promise<void> => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  const cognitoEnvParams = isManager
    ? env.managers.cognito
    : env.thirdPartyUsers.cognito;

  return new CognitoIdentityServiceProvider({
    region: cognitoEnvParams.region,
  })
    .initiateAuth({
      AuthFlow: "REFRESH_TOKEN",
      ClientId: cognitoEnvParams.clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken as string,
      },
    })
    .promise()
    .then(({ AuthenticationResult }) => {
      setIdToken(AuthenticationResult?.IdToken as string);
    });
};
