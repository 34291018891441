import React, { FC, ElementType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { Path } from "../../types/pathType";
import { useUser } from "../../hooks/useUser";

type PublicRouteProps = RouteProps & {
  component: ElementType;
  isRestricted?: boolean;
};

export const PublicRoute: FC<PublicRouteProps> = ({
  component: Component,
  isRestricted,
  ...rest
}) => {
  const { user, isLoading } = useUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return;
        }

        return user && isRestricted ? (
          <Redirect to={Path.THIRD_PARTY_API_DOCUMENTATION} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};
