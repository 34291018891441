import React, { useState } from "react";

import { Content } from "gls/lib";
import { useHistory } from "react-router-dom";

import { Path } from "shared/types/pathType";
import { useFeedback } from "shared/hooks/useFeedback";
import { useForgotPassword } from "shared/hooks/useForgotPassword";

import { NewPasswordForm } from "./NewPasswordForm";
import { ForgotPasswordUsernameForm } from "./ForgotPasswordUsernameForm";

export const ForgotPasswordPage = () => {
  const [username, setUsername] = useState<string>();

  const history = useHistory();
  const feedback = useFeedback();
  const forgotPassword = useForgotPassword();

  const onUsernameSubmit = (username: string) => {
    forgotPassword
      .sendVerificationCode(username)
      .then(() => {
        feedback.showPositiveFeedback(`
          If the account exists, you will have
          received a verification code in your email.
        `);

        setUsername(username);
      })
      .catch(({ message }: Error) => {
        feedback.showNegativeFeedback(message);
      });
  };

  const onNewPasswordSubmit = (
    newPassword: string,
    verificationCode: string,
  ) => {
    forgotPassword
      .setNewPassword({
        newPassword,
        verificationCode,
      })
      .then(() => {
        feedback.showPositiveFeedback(`
          Password has been successfully changed. Use to log in
          from now on.
        `);

        history.push(Path.LOGIN);
      })
      .catch(({ message }: Error) => {
        feedback.showNegativeFeedback(message);
      });
  };

  return (
    <Content height="75vh" verticalAlign="center" horizontalAlign="center">
      {!username && <ForgotPasswordUsernameForm onSubmit={onUsernameSubmit} />}

      {username && <NewPasswordForm onSubmit={onNewPasswordSubmit} />}
    </Content>
  );
};
