import React, { FC } from "react";
import { Content } from "gls/lib";

import { Loader } from "../loader/Loader";

export const TableBodyLoading: FC = () => {
  return (
    <tbody>
      <tr>
        <td colSpan={100}>
          <Content height={350} verticalAlign="center" horizontalAlign="center">
            <Loader />
          </Content>
        </td>
      </tr>
    </tbody>
  );
};
