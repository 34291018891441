import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import moment, { Moment } from "moment";
import React, { FC, useState } from "react";

import { stylesheet } from "typestyle";
import { Content, Vertical } from "gls/lib";
import { SingleDatePicker as DatePicker } from "react-dates";

import { FormInputLabel } from "./FormInputLabel";
import { FormInputErrors } from "./FormInputErrors";
import { InputProps, useInput } from "../../hooks/useInput";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

interface Props extends InputProps<Moment> {
  label?: string;
  placeholder?: string;
  isOutsideRange?: (date: Moment) => boolean;
}

export const FormDatePickerInput: FC<Props> = (props) => {
  const [focused, setFocused] = useState(false);

  const { value, errors, isDirty, onChangeHandler } = useInput<Moment | null>({
    ...props,
  });

  return (
    <Vertical className={classNames.field} spacing={0}>
      <FormInputLabel label={props.label} />

      <Content className={classNames.input}>
        <DatePicker
          id={`${props.name}-datepicker`}
          date={value ?? null}
          focused={focused}
          noBorder={true}
          numberOfMonths={1}
          onDateChange={onChangeHandler}
          onFocusChange={() => setFocused(!focused)}
          initialVisibleMonth={() => moment(props.initialValue) || moment()}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={props.isOutsideRange}
          displayFormat="LL"
        />
      </Content>

      <FormInputErrors errors={errors} isDirty={isDirty} />
    </Vertical>
  );
};

const classNames = stylesheet({
  field: {
    margin: 0,
    width: "100%",
  },
  input: {
    border: `1px solid ${COLORS.dark3}`,
    borderRadius: "5px",
    background: "none",
    width: "100%",
    $nest: {
      ".SingleDatePicker": {
        width: "100%",
      },
      ".SingleDatePicker_picker": {
        background: COLORS.dark3,
      },
      ".DayPicker": {
        top: -1,
        background: COLORS.dark3,
      },
      ".SingleDatePickerInput": {
        width: "100%",
        background: "none",
      },
      ".DateInput": {
        width: "100%",
        background: "none",
      },
      ".DateInput_input": {
        background: "none",
        fontSize: SIZES.fontSize2,
        color: COLORS.light4,
        padding: "0.4rem 0.65rem",
        $nest: {
          "&::placeholder": {
            color: COLORS.light2,
          },
        },
      },
      ".DateInput_input__focused": {
        boxShadow: `0 0 0 1px ${COLORS.primary}`,
        border: "none",
        borderRadius: SIZES.borderRadius,
      },
      ".CalendarMonthGrid": {
        background: COLORS.dark3,
      },
      ".CalendarMonth": {
        background: COLORS.dark3,
      },
      ".CalendarDay__default": {
        color: COLORS.light4,
        background: COLORS.dark1,
        border: `1px solid ${COLORS.dark3}`,
      },
      ".CalendarDay__blocked_out_of_range": {
        color: COLORS.dark3,
        background: COLORS.dark1,
        border: `1px solid ${COLORS.dark3}`,
      },
      ".DayPickerNavigation_button__default": {
        border: "none",
        background: COLORS.dark3,
      },
      ".DayPicker_weekHeaders": {
        top: -7,
      },
      ".DayPicker_weekHeader": {
        color: COLORS.light2,
      },
      ".DayPickerNavigation_svg__horizontal": {
        fill: COLORS.light2,
        background: COLORS.dark3,
      },
      ".DateInput_fangStroke": {
        fill: COLORS.dark3,
        stroke: COLORS.dark1,
      },
      ".CalendarMonth_caption": {
        color: COLORS.light4,
        $nest: {
          strong: {
            fontWeight: "normal",
          },
        },
      },
    },
  },
});
