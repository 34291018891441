import React, { FC, ReactElement } from "react";

import { Vertical } from "gls/lib";
import { stylesheet } from "typestyle";

import { FormInputLabel } from "./FormInputLabel";
import { FormInputErrors } from "./FormInputErrors";
import { InputProps, useInput } from "shared/hooks/useInput";

import { SIZES } from "../../variables/sizes";
import { COLORS } from "../../variables/colors";

interface Props extends InputProps<string> {
  label?: string | ReactElement;
  isPassword?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
}

export const FormTextInput: FC<Props> = (props) => {
  const input = useInput({ ...props });

  return (
    <Vertical className={classNames.field} spacing={0}>
      <FormInputLabel label={props.label} isRequired={props.isRequired} />

      <input
        disabled={props.isDisabled}
        type={props.isPassword ? "password" : "text"}
        value={input.value ?? ""}
        onBlur={input.onBlurHandler}
        onChange={({ target }) => input.onChangeHandler(target.value)}
        className={classNames.input}
        placeholder={props.placeholder}
      />

      <FormInputErrors {...input} />
    </Vertical>
  );
};

const classNames = stylesheet({
  field: {
    margin: 0,
    width: "100%",
  },
  input: {
    padding: "0.5rem 0.75rem",
    color: COLORS.light4,
    border: `1px solid ${COLORS.dark3}`,
    borderRadius: "5px",
    background: "none",
    fontSize: SIZES.fontSize2,
    width: "100%",
    $nest: {
      "&:focus": {
        boxShadow: `0 0 0 1px ${COLORS.primary}`,
        outline: "none",
      },
      "&:disabled": {
        color: COLORS.light2,
      },
    },
  },
});
