import React, { useEffect, useRef, useState } from "react";
import copy from "copy-to-clipboard";

import { stylesheet } from "typestyle";
import { Content, Vertical } from "gls/lib";

import { Form } from "shared/components/form/Form";
import { Button } from "shared/components/button/Button";
import { FormTextInput } from "shared/components/form/FormTextInput";

import { FormState } from "shared/types/formTypes";
import { useFeedback } from "shared/hooks/useFeedback";
import { isMandatory } from "shared/validators/isMandatoryValidator";

import { SIZES } from "shared/variables/sizes";

import moment from "moment";
import { COLORS } from "shared/variables/colors";

interface Props {
  secret: string;
  onSubmit: (otp: string) => void;
}

const COGNITO_SESSION_DURATION_IN_MS = 180000;
const SECOND_IN_MS = 1000;

export const OTPSetUpForm: React.FC<Props> = ({ secret, onSubmit }) => {
  const { showPositiveFeedback } = useFeedback();
  const [timeToExpire, setTimeToExpire] = useState<number>(
    COGNITO_SESSION_DURATION_IN_MS,
  );

  useEffect(() => {
    if (timeToExpire > 0) {
      setTimeout(() => {
        setTimeToExpire(timeToExpire - SECOND_IN_MS);
      }, 1000);
    }
  }, [timeToExpire]);

  const onSubmitHandler = ({ otp }: FormState) => {
    onSubmit(otp.value);
  };

  const onClickHandler = () => {
    copy(secret);
    showPositiveFeedback("Code successfully copied to clipboard");
  };

  return (
    <Form
      title="OTP client verification"
      onSubmit={onSubmitHandler}
      submitButtonText="Verify"
    >
      <Vertical width={300} spacing={15}>
        <Content className={classNames.info}>
          Now we need to verify your OTP client. You can use whichever you want
          (for example, Google Authenticator). Make sure you will always have
          access to it as you will need an OTP code everytime you log in.
          <Button
            onClick={onClickHandler}
            className={classNames.code}
            type="secondary"
          >
            Copy to clipboard!
          </Button>
        </Content>

        {timeToExpire <= 0 ? (
          <Content>Expired</Content>
        ) : (
          <Content>Expires in: {moment(timeToExpire).format("mm:ss")}</Content>
        )}

        <FormTextInput
          label="One-time password (OTP)"
          name="otp"
          validators={[isMandatory]}
        />
      </Vertical>
    </Form>
  );
};

const classNames = stylesheet({
  info: {
    background: COLORS.dark2,
    borderRadius: SIZES.borderRadius,
    padding: "0.65rem",
    textAlign: "center",
    color: COLORS.light2,
    border: `1px solid ${COLORS.dark3}`,
    fontSize: "15px",
    lineHeight: "1.35rem",
  },
  code: {
    marginTop: 15,
    width: "100%",
  },
});
