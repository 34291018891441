import { percent, rem } from "csx";
import { Content, Horizontal, Vertical } from "gls/lib";
import { useState } from "react";
import { style } from "typestyle";

export const ThirdPartyApiDocumentationDescription = () => {
  const [showDescription, setShowDescription] = useState(true);

  return (
    <Vertical spacing={rem(1)} padding={[0, 0, rem(1), 0]}>
      <Horizontal
        spacing={rem(0.5)}
        onClick={() => {
          setShowDescription(!showDescription);
        }}
      >
        <b>Description</b>
        <Content className={exampleToggle}>
          ({showDescription ? "hide" : "show"})
        </Content>
      </Horizontal>
      {showDescription && (
        <Vertical spacing={0}>
          <Content>
            Our third-party api is a graphql endpoint, meaning clients can query
            for the data that they need.
          </Content>
          <Content>
            Data is updated once a day, and the availability of the data depends
            on how well the data is backed up.{" "}
          </Content>
          <Content>
            The login methods are using the same credentials made to log into
            developer.observe.tech.
          </Content>
          <Content>
            Each API call allows you to query for 1 site, 1 cage, 1 day at a
            time.
          </Content>
          <Content>
            There is a rate limiting of 5 queries per second per user.
          </Content>
          <ol className={descriptionList}>
            <li>
              <b>Aggregate Data</b>
            </li>
            <Content width={percent(50)}>
              A gathering of all the data organized in 5 second groupings of
              what the AI sees on site. Observe automatically cleans the data
              for you. Because this is a massive data size, data is organized
              into 15m time groups for you to query by.
            </Content>
            <li>
              <b>Feeding Data</b>
            </li>
            <Content width={percent(50)}>
              A gathering of all the feed data organized by each feeding visit.
            </Content>
          </ol>
          <Content>
            You can click through the interactive documentation below to see
            example and data structure of the GraphQL.
          </Content>
        </Vertical>
      )}
    </Vertical>
  );
};

const exampleToggle = style({
  cursor: "pointer",
  $nest: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const descriptionList = style({
  $nest: {
    "& li": {
      lineHeight: rem(2),
    },
  },
});
