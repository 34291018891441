import React, { FC, useMemo, useState } from "react";

import { style } from "typestyle";
import { store } from "graphql-playground-react";
import { Provider } from "react-redux";
import { buildSchema } from "graphql";
import { GraphQLCodeBlock } from "react-graphql-syntax-highlighter";
import { percent, px, rem, rgba } from "csx";
import { Content, Horizontal, Stretch, Vertical } from "gls/lib";

import { Loader } from "shared/components/loader/Loader";
import { useDocs } from "shared/hooks/useDocs";
import { DocsType } from "shared/types/docsType";
import { DocExplorer } from "shared/components/schema-explorer/DocExplorer";
import { PageContainer } from "shared/components/page-container/PageContainer";

import { SIZES } from "shared/variables/sizes";

import "./style.css";
import { ThirdPartyApiDocumentationDescription } from "./ThirdPartyApiDocumentationDescription";

export const ThirdPartyApiDocumentationPage: FC = () => {
  const docs = useDocs();

  const [showExample, setShowExample] = useState(true);

  const thirdPartyApiDocs = useMemo(() => {
    return docs?.[DocsType.THIRD_PARTY_API] ?? null;
  }, [docs]);

  const thirdPartyApiSchema = useMemo(() => {
    return thirdPartyApiDocs ? buildSchema(thirdPartyApiDocs) : null;
  }, [thirdPartyApiDocs]);

  return (
    <PageContainer>
      <Stretch width="100%" horizontalAlign="center" scroll="vertical">
        <Vertical
          spacing={5}
          width="100%"
          maxWidth={SIZES.container}
          padding={[0, 20]}
        >
          <Provider store={store}>
            {thirdPartyApiSchema ? (
              <>
                <Vertical spacing={10} className={description}>
                  <Content className={title}>
                    Observe Third-Party API Documentation
                  </Content>
                  <ThirdPartyApiDocumentationDescription />
                  <Horizontal spacing={rem(0.5)}>
                    <b>Example query</b>
                    <div
                      className={exampleToggle}
                      onClick={() => {
                        setShowExample(!showExample);
                      }}
                    >
                      ({showExample ? "hide" : "show"})
                    </div>
                  </Horizontal>
                  {showExample && (
                    <Stretch
                      width={percent(100)}
                      className={exampleQueryContainer}
                    >
                      <GraphQLCodeBlock
                        className={exampleQuery}
                        src={`query { sites(companyId: 10) { name clients { id email } } }`}
                      />
                    </Stretch>
                  )}
                </Vertical>
                <div className="graphiql-container">
                  <div
                    className={style({
                      display: "block",
                      position: "relative",
                      width: "100%",
                    })}
                  >
                    <DocExplorer schema={thirdPartyApiSchema} />
                  </div>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </Provider>
        </Vertical>
      </Stretch>
    </PageContainer>
  );
};

const title = style({
  fontSize: px(36),
  fontWeight: "bold",
  fontFamily: "sans-serif",
  paddingTop: px(50),
});

const exampleQuery = style({
  $nest: {
    "& .property": {
      color: "#61affe",
    },
    "& .attribute": {
      color: "#6c46ff",
    },
    "& .punctuation": {
      color: "#555",
    },
    "& pre": {
      margin: px(5),
    },
  },
});

const exampleQueryContainer = style({
  background: rgba(0, 0, 0, 0.05).toString(),
  padding: px(5),
  borderRadius: px(4),
});

const description = style({
  $nest: {
    "& span, & p": {
      fontFamily: "monospace",
    },
  },
});

const exampleToggle = style({
  cursor: "pointer",
  $nest: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});
