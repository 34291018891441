import { useState } from "react";
import { getCognitoParams } from "shared/utils/cognitoUtils";

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

interface UseForgotPasswordResponse {
  setNewPassword: (params: {
    newPassword: string;
    verificationCode: string;
  }) => Promise<void>;
  sendVerificationCode: (email: string) => Promise<void>;
}

export const useForgotPassword = (): UseForgotPasswordResponse => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();

  return {
    sendVerificationCode: (username: string) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: new CognitoUserPool({
          ClientId: getCognitoParams(username).clientId,
          UserPoolId: getCognitoParams(username).userPoolId,
        }),
      });

      setCognitoUser(cognitoUser);

      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: resolve,
          onFailure: reject,
        });
      });
    },
    setNewPassword: ({ verificationCode, newPassword }) => {
      return new Promise((resolve, reject) => {
        (cognitoUser as CognitoUser).confirmPassword(
          verificationCode,
          newPassword,
          {
            onSuccess: resolve,
            onFailure: reject,
          },
        );
      });
    },
  };
};
